<template>
  <svg viewBox="0 0 32 32">
    <path
      d="M17.3332 17.3332V24.7798L19.7705 22.3425L21.6572 24.2292L15.9999 29.8852L10.3425 24.2292L12.2292 22.3425L14.6665 24.7798V17.3332H17.3332ZM15.9999 2.6665C18.2892 2.66661 20.4986 3.50799 22.2081 5.03066C23.9176 6.55333 25.008 8.65112 25.2719 10.9252C26.9309 11.3776 28.3782 12.3986 29.3607 13.8099C30.3433 15.2211 30.7987 16.9328 30.6473 18.6457C30.496 20.3586 29.7475 21.9639 28.5326 23.1809C27.3178 24.398 25.7138 25.1494 24.0012 25.3038V22.6185C24.6148 22.5309 25.2047 22.3219 25.7366 22.0038C26.2686 21.6857 26.7318 21.2648 27.0993 20.7657C27.4668 20.2666 27.7312 19.6993 27.877 19.097C28.0229 18.4946 28.0473 17.8692 27.9488 17.2573C27.8503 16.6453 27.631 16.0592 27.3035 15.533C26.976 15.0068 26.547 14.5511 26.0415 14.1925C25.536 13.8339 24.9641 13.5796 24.3592 13.4444C23.7543 13.3092 23.1286 13.2959 22.5185 13.4052C22.7273 12.433 22.716 11.4265 22.4855 10.4592C22.2549 9.49202 21.8109 8.58862 21.186 7.81521C20.5611 7.0418 19.7711 6.41798 18.8738 5.98943C17.9766 5.56088 16.9949 5.33846 16.0005 5.33846C15.0062 5.33846 14.0245 5.56088 13.1272 5.98943C12.23 6.41798 11.44 7.0418 10.8151 7.81521C10.1901 8.58862 9.74614 9.49202 9.51557 10.4592C9.285 11.4265 9.27371 12.433 9.48253 13.4052C8.26607 13.1767 7.00868 13.4409 5.98698 14.1395C4.96529 14.8382 4.26297 15.914 4.03453 17.1305C3.80609 18.347 4.07024 19.6043 4.76887 20.626C5.46751 21.6477 6.5434 22.3501 7.75986 22.5785L7.99986 22.6185V25.3038C6.28713 25.1496 4.68307 24.3984 3.46804 23.1814C2.25301 21.9645 1.50429 20.3592 1.35276 18.6463C1.20122 16.9333 1.6565 15.2215 2.63902 13.8102C3.62154 12.3989 5.0688 11.3777 6.72786 10.9252C6.99147 8.65101 8.08173 6.55305 9.7913 5.03032C11.5009 3.5076 13.7105 2.66632 15.9999 2.6665V2.6665Z"
    />
  </svg>
</template>

<script>
export default {
  name: "CloudIcon",
};
</script>
